import React from 'react';
import './EverythingElse2.css';
import EverythingElse2__LitePaperModule from './EverythingElse2__LitePaperModule.js'
import baseballCap from './img/baseball-cap.png';
// import increase from './img/increase.png';
import increase from './img/bar-chart.png';
import beaker from './img/beaker.png';
import wallet from './img/wallet.png';
import marketing from './img/promotion.png';
// import partnership from './img/partnership.png';
import partnership from './img/deal (1).png';
import transparency from './img/study.png';
import alex from './img/alex.jpg';
import mashhood from './img/mashhood.jpeg';
import question from './img/question_mark2.jpg';

const EverythingElse2 = (props) =>{

    return (
        <div className='everything-else2'>
{/*          <p className='everything-else2__content'>
	          Hi! I am sha257, an established Silicon Valley software engineer looking to revolutionize the crypto industry.
	          <br/><br/>
	          WhaleSafe is an important first step. This token aims to reduce the influence whales (individuals who own large sums of tokens) have on the price and volatility. When whales sell off their tokens, the value of our tokens tank.
	          <br/><br/>
	          WhaleSafe has two technical novelties that reduce the likelihood of whale influence!
	          <br/><br/>
	          <ol>
	            <li>WhaleCap: No wallet can hold more than 1.5% of the tokens.</li>
	            <li>RisingWhaleCap: We gently increase the WhaleCap from 0.5% to 1.5% over a period of 3 months.</li>
	          </ol>
          </p>*/}
          <p className='everything-else2__content'>
	          <EverythingElse2__LitePaperModule
		          name='Alex Whedon'
		          title='CEO, Dev'
		          text='Alex specializes in artificial intelligence software engineering and research. He has built Web3.0 tech for large Silicon Valley companies like Glassdoor, Indeed, and Academia.edu. He currently works at Megagon Labs. WhaleSafe is the first of a few projects he will be releasing in the crypto space, each contributing to a larger vision of transforming the space.'
		          linkedin='https://linkedin.com/in/alexander-whedon'
		          imgSrc={ alex }/>
	          <EverythingElse2__LitePaperModule
	              name='Mashhood Asalkhou'
	              title='Designer'
	              text="Mashhood studied design at San Jose State University. He has experience in both design and operations and is well equipped to help this token scale. He is currently in senior operations at GetAround, a Silicon Valley transportation tech company. Mashhood's photography and videography has been featured in various publications, including the Sacramento Bee."
	              linkedin='https://www.linkedin.com/in/mashhood-asalkhou-625128104'
	              imgSrc={ mashhood }/>
	          <EverythingElse2__LitePaperModule
	              name='Want to join?'
	              title='Various Roles'
	              text='We are looking to expand our team! Check out LinkedIn for job postings in the near future.'
	              linkedin='https://www.linkedin.com/company/whalesafecrypto'
	              imgSrc={ question }/>
	      </p>
        </div>
    );

}

export default EverythingElse2;